import SignUp from "./SignUp";
import SignIn from "./SignIn";
import NotFound from "./NotFound";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import ResetPassword from "./ResetPassword";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import PaymentSetting from "./PaymentSettings";
import Terms from "../Term_Condition";
import { SUB_DIRCTORY } from "../../../app_config";

const settings = {
  activeLayout: "layout1",
  layout1Settings: {
    topbar: {
      show: false,
    },
    leftSidebar: {
      show: false,
      mode: "close",
    },
  },
  layout2Settings: {
    mode: "full",
    topbar: {
      show: false,
    },
    navbar: { show: false },
  },
  secondarySidebar: { show: false },
  footer: { show: false },
};

const sessionRoutes = [
  {
    path: `${SUB_DIRCTORY}/session/signup`,
    component: SignUp,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/session/signin`,
    component: SignIn,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/session/forgot-password`,
    component: ForgotPassword,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/session/reset-password`,
    component: ResetPassword,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/session/set-password`,
    component: SetPassword,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/session/change-password`,
    component: ChangePassword,
  },
  {
    path: `${SUB_DIRCTORY}/session/payment-setting`,
    component: PaymentSetting,
  },
  {
    path: `${SUB_DIRCTORY}/session/profile`,
    component: Profile,
  },
  {
    path: `${SUB_DIRCTORY}/session/404`,
    component: NotFound,
    settings,
  },
  {
    path: `${SUB_DIRCTORY}/terms-of-service`,
    component: Terms,
    settings,
  }
];

export default sessionRoutes;
