import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const bookingChargeRoute = [
 {
  path: `${SUB_DIRCTORY}/account-receivable`,
  component: React.lazy(() => import("./bookingCharge")),
 },
];

export default bookingChargeRoute;