import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const StaffRoutes = [
  {
    path: `${SUB_DIRCTORY}/staff/:action/:id?`,
    component: React.lazy(() => import("./AddStaff")),
  },
  {
    path: `${SUB_DIRCTORY}/staff`,
    component: React.lazy(() => import("./Staff")),
  },
];

export default StaffRoutes;