/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import { getNavigationByUser } from "../redux/actions/NavigationAction";
// import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
// import firebaseAuthService from "../services/firebase/firebaseAuthService";
import history from "../../history";
import accountService from "../services/accountService";
import { SUB_DIRCTORY } from "../../app_config";

const checkJwtAuth = async (setUserData) => {
  // You need to send token to your server to check token is valid
  // modify loginWithToken method in jwtService
  // let user = await jwtAuthService.loginWithToken();
  let user = await accountService.loginWithToken();
  if (user) {
    setUserData(user);
    if (window.location.href.indexOf(`${SUB_DIRCTORY}/session/signin`) > -1) {
      history.push({
        pathname: `${SUB_DIRCTORY}/`,
      });
    }
  } else {
    if (
      window.location.href.indexOf(`${SUB_DIRCTORY}/session/forgot-password`) === -1 &&
      window.location.href.indexOf(`${SUB_DIRCTORY}/session/set-password`) === -1 &&
      window.location.href.indexOf(`${SUB_DIRCTORY}/session/signup`) === -1 &&
      window.location.href.indexOf(`${SUB_DIRCTORY}/session/reset-password`) === -1
      && window.location.href.indexOf(`${SUB_DIRCTORY}/terms-of-service`) === -1
    ) {
      history.push({
        pathname: `${SUB_DIRCTORY}/session/signin`,
      });
    }
    // history.push({
    //   pathname: "/session/signin",
    // });
  }
  return user;
};


const Auth = ({ children, setUserData, getNavigationByUser }) => {
  setUserData(localStorageService.getItem("auth_user"));

  useEffect(() => {
    checkJwtAuth(setUserData);
    getNavigationByUser();
  }, [setUserData, getNavigationByUser]);

  return <Fragment>{children}</Fragment>;
};

const mapStateToProps = (state) => ({
  setUserData: PropTypes.func.isRequired,
  getNavigationByUser: PropTypes.func.isRequired,
  login: state.login,
});

export default connect(mapStateToProps, { setUserData, getNavigationByUser })(
  Auth
);