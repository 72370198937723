import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const CheckInCheckoutRoutes = [
  //   {
  //     path: "/staff/:action/:id?",
  //     component: React.lazy(() => import("./AddStaff")),
  //   },
  {
    path: `${SUB_DIRCTORY}/checkIn-checkOut`,
    component: React.lazy(() => import("./CheckInCheckout")),
  },
];

export default CheckInCheckoutRoutes;