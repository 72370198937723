import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const whiteListRoutes = [
 {
  path: `${SUB_DIRCTORY}/whiteList-vehicle/:action/`,
  component: React.lazy(() => import("./addWhiteListVehicle")),
 },
 {
  path: `${SUB_DIRCTORY}/whiteList-vehicle`,
  component: React.lazy(() => import("./whiteListVehicleList")),
 },
];

export default whiteListRoutes;