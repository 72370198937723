import { SUB_DIRCTORY } from "../../../app_config";
import React from "react";
const CameraRoutes = [
{
  path: `${SUB_DIRCTORY}/camera-setting/:action/:id?`,
  component: React.lazy(() => import("./AddCamera")),
 },
 {
   path: `${SUB_DIRCTORY}/camera-setting`,
   component: React.lazy(() => import("./CameraList")),
 },
];

export default CameraRoutes;
