import axios from "axios";
import localStorageService from "./localStorageService";
import { postApiCall } from "./_common";

class accountService {

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = (methodName, parameter) => {
    return new Promise((resolve, reject) => {
      return postApiCall(methodName, parameter)
        .then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error)
        });
    }).then(data => {
      // Login successful
      // Save token
      if (data.Status) {
        this.setSession(data.Data.JwtToken);
        // Set user
        this.setUser(data.Data);
        this.setRoles(data.Data.Roles);
      }
      return data;
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      const that = this;
      setTimeout(() => {
        resolve(that.getUser());
      }, 100);
    }).then(data => {
      // Token is valid
      if (data) {
        this.setSession(data.JwtToken);
        this.setUser(data);
        this.setRoles(data.Roles);
      }
      return data;
    });
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
    localStorage.clear();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }
  
  getUser = () => {
    return localStorageService.getItem("auth_user");
  }
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
  
  setRoles = (roles) => {
    localStorageService.setItem("user_roles", roles);
  }

  getRoles = () => {
    return localStorageService.getItem("user_roles");
  }

  removeUser = () => {
    localStorage.removeItem("user_roles");
  }
}

export default new accountService();
