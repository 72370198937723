import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";
// import { authRoles } from "../../auth/authRoles";

const reportRoutes = [
 {
  path: `${SUB_DIRCTORY}/Report`,
  component: React.lazy(() => import("./index")),
 },
 
];

export default reportRoutes;
