import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const liveReportRoutes = [
 {
  path: `${SUB_DIRCTORY}/live-reports`,
  component: React.lazy(() => import("./index")),
 },
 
];

export default liveReportRoutes;
