import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const QrActivityLogsRoutes = [
  {
    path: `${SUB_DIRCTORY}/qr-activity-logs`,
    component: React.lazy(() => import("./QrActivityLogs")),
  },
];

export default QrActivityLogsRoutes;