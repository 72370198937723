import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const AnprRoutes = [

{
path: `${SUB_DIRCTORY}/anpr-feed`,
component: React.lazy(() => import("./AnprFeedDetails")),
}
]
export default AnprRoutes;