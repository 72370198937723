import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const QrSettingRoutes = [
  
  {
    path: `${SUB_DIRCTORY}/qr-setting/:action/`,
    component: React.lazy(() => import("./Addqrcode")),
  },
  {
    path: `${SUB_DIRCTORY}/qr-setting/`,
    component: React.lazy(() => import("./QRSettings")),
  },
  {
    path: `${SUB_DIRCTORY}/monthly-qr-setting/:action/`,
    component: React.lazy(() => import("./Addqrcode")),
  },
  {
    path: `${SUB_DIRCTORY}/monthly-qr-setting/`,
    component: React.lazy(() => import("./QRSettings")),
  },

 
];

export default QrSettingRoutes;
