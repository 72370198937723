import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import { MatxLoading } from "../../../matx";
import history from "../../../history";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  Icon,
  Paper,
  Button,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormLabel,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import { commonMsg, showToast } from "./../../../constants";
import { getApiCall, postApiCall, } from "../../../app/services/_common";
import {
  getUserProfileDetails,
  editUserProfile,
  SUB_DIRCTORY,
  imageMaxSize,
  AccessKey,
  SecretKey,
  ImageBucketName,
  region,
  AdminProfiles,
} from "../../../app_config";
import { connect } from "react-redux";
import accountService from "../../services/accountService";
import Moment from "moment";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { RouteComponentProps } from "react-router";
import { setUserData } from "./../../redux/actions/UserActions";
import { uploadFile } from 'react-s3';

interface IProps extends RouteComponentProps, WithSnackbarProps {
  user: any;
  setUserData: (postData: any) => any;
}

interface IState {
  actionType: string;
  userId: string;
  firstName: string;
  lastName: string;
  gender: string;
  email: string;
  mobile: string;
  logoSrc: string;
  MenuProps: any;
  genderError: boolean;
  locationError: boolean;
  sendLogoData: any;
  logoError: boolean;
  loading: boolean;
  LogoUrl: string;
}
const config = {
  bucketName: ImageBucketName,
  region: region,
  dirName: AdminProfiles,
  accessKeyId: AccessKey,
  secretAccessKey: SecretKey,
}

class Profile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      actionType: "edit",
      userId: "",
      firstName: "",
      lastName: "",
      gender: "",
      email: "",
      mobile: "",
      logoSrc: "",
      MenuProps: {
        PaperProps: {
          style: {
            maxHeight: 48 * 4.5 + 8,
            width: 250,
          },
        },
      },
      genderError: false,
      locationError: false,
      sendLogoData: {},
      logoError: false,
      loading: false,
      LogoUrl: "",
    };
  }

  componentDidMount() {
    this.setState({ userId: this.props.user.Id }, () => {
      this.getUserDetails(this.state.userId);
    });

    ValidatorForm.addValidationRule("isValidPhone", (value) => {
      let validPhoneRegx = /^[\+]?[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,9}$/; //eslint-disable-line
      if (validPhoneRegx.test(value)) {
        return true;
      }
      return false;
    });
    const { user } = this.props;
    this.setState({ LogoUrl: user.LogoUrl })
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule("isValidPhone");
  }

  getUserDetails(userId: string) {
    const parms = `UserId=${userId}`;
    getApiCall(getUserProfileDetails, parms)
      .then((response) => {
        if (response.Status) {
          if (response.Data) {
            this.setState({ loading: false });
            this.setData(response.Data);
          }
        } else {
          this.setState({ loading: false });
          showToast(
            this.props.enqueueSnackbar,
            response.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  }

  setData = (data: any) => {
    this.setState({
      firstName: data.FirstName.trim(),
      lastName: data.LastName.trim(),
      gender: data.Gender,
      email: data.Email,
      mobile: data.Mobile,
      logoSrc: data.ProfilePic,
    });
  };

  handleChange = (event: any) => {
    event.persist();
    this.setState(
      { [event.target.name]: event.target.value } as Pick<IState, keyof IState>,
      () => {
        if (event.target.name === "gender" && this.state.gender !== "") {
          this.setState({ genderError: false });
        }
      }
    );
  };

  handleSubmit = (event: any) => {
    let { firstName, lastName, gender, mobile, logoSrc, userId } =
      this.state;
    let postData = {
      UserId: +userId,
      FirstName: firstName,
      LastName: lastName,
      Gender: gender,
      Mobile: mobile,
      CreatedBy: userId,
      CreatedDate: Moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      UpdatedDate: Moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
      ProfilePic: logoSrc,
    };

    postApiCall(editUserProfile, postData)
      .then((res) => {
        if (res.Status) {
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Success",
            "success",
            this.props.closeSnackbar
          );
          let userDetails = accountService.getUser();
          userDetails.FirstName = firstName;
          userDetails.LastName = lastName;
          userDetails.ProfilePic = logoSrc;
          this.props.setUserData(userDetails);
          accountService.setUser(userDetails);
          history.push({
            pathname: `${SUB_DIRCTORY}/`,
          });
        } else {
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      })
      .catch((error) => {
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  };

  ClearFrom = () => {
    let { firstName, lastName, gender,  mobile } = this.state;

    firstName = "";
    lastName = "";
    gender = "Male";
    mobile = "";
    this.setState({
      firstName,
      lastName,
      gender,
      mobile,
    });
    history.push({
      pathname: `${SUB_DIRCTORY}/`,
    });
  };

  uploadsImage = (event: any) => {
    this.setState({ loading: true, });

    if (event.target.files.length === 0) return;
    else if (!event.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      showToast(
        this.props.enqueueSnackbar,
        "Please select only Image format",
        "Error",
        "error",
        this.props.closeSnackbar
      );
      this.setState({
        loading: false, logoSrc: this.state.logoSrc,
      });
    }
    else if (event.target.files[0].size > imageMaxSize) {
      showToast(
        this.props.enqueueSnackbar,
        commonMsg.IMG_SIZ_MSG,
        "Error",
        "error",
        this.props.closeSnackbar
      );
      this.setState({
        loading: false, logoSrc: this.state.logoSrc,
      });
    }
    else if (event.target.files[0].type === "") {
      showToast(
        this.props.enqueueSnackbar,
        "Please select only Image format",
        "Error",
        "error",
        this.props.closeSnackbar
      );
      this.setState({ loading: false, logoSrc: this.state.logoSrc });
    }
    else {
      this.setState({
        loading: true,
        sendLogoData: URL.createObjectURL(event.target.files[0]),
      });

      uploadFile(event.target.files[0], config)
        .then((data: any) => {
          this.setState({ loading: false, logoSrc: data.location, })
        })
        .catch((err: any) => {
          console.error(err);
          this.setState({ loading: false });
        })
    }
  };



  render() {
    const {
      // actionType,
      firstName,
      lastName,
      gender,
      email,
      mobile,
      // MenuProps,
      genderError,
      logoSrc,
      sendLogoData,
      logoError,
      loading,
    } = this.state;
    return (
      <Fragment>
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <h4 className="m-0 pb-1px text-16 capitalize align-middle">
              Profile</h4>
          </div>
          <div className="custom-wrapper">
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}
              onError={(errors) => {
                this.state.gender === ""
                  ? this.setState({ genderError: true })
                  : this.setState({ genderError: false });
              }}
            >
              <Grid container spacing={3}>
                <Grid className="marginauto" item xs={12} sm={12} md={6} lg={6}>
                  <Paper className="paper">
                    <Card>
                      <CardContent>
                        <div>
                          <TextValidator
                            className="mb-4 w-full"
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            inputProps={
                              { maxLength: 50 }
                            }
                            validators={commonMsg.FIRST_NAME_VALIDATORS}
                            errorMessages={commonMsg.FIRST_NAME_ERR}
                            onChange={this.handleChange}
                            type="text"
                            required
                          />
                          <TextValidator
                            className="mb-4 w-full"
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            inputProps={
                              { maxLength: 50 }
                            }
                            value={lastName}
                            validators={commonMsg.FIRST_NAME_VALIDATORS}
                            errorMessages={commonMsg.LAST_NAME_ERR}
                            onChange={this.handleChange}
                            type="text"
                            required
                          />
                          <FormControl
                            component="fieldset"
                            className="marginTenpx"
                            error={genderError}
                          >
                            <FormLabel component="legend" required>Gender</FormLabel>
                            <RadioGroup
                              value={gender}
                              name="gender"
                              onChange={this.handleChange}
                              row
                            >
                              <FormControlLabel
                                value="M"
                                control={<Radio color="primary" />}
                                label="Male"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="F"
                                control={<Radio color="primary" />}
                                label="Female"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="O"
                                control={<Radio color="primary" />}
                                label="Others"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {genderError ? commonMsg.GENDER_ERR : ""}
                            </FormHelperText>
                          </FormControl>
                          <TextValidator
                            className="mb-4 w-full"
                            id="email"
                            name="email"
                            label="Email"
                            value={email}
                            inputProps={
                              { maxLength: 100 }
                            }
                            disabled={true}
                            validators={commonMsg.EMAIL_VALIDATORS}
                            errorMessages={commonMsg.EMAIL_ERR}
                            onChange={this.handleChange}
                          />
                          <TextValidator
                            required
                            type="text"
                            className="mb-4 w-full"
                            id="mobile"
                            name="mobile"
                            label="Phone"
                            inputProps={
                              { maxLength: 15 }
                            }
                            value={mobile.replace(
                              /^(\d{3})(\d{3})(\d{4}).*/,
                              "$1-$2-$3"
                            )}
                            validators={["required", "isValidPhone"]}
                            errorMessages={commonMsg.PHONE_ERR}
                            onChange={this.handleChange}
                          />
                          <div className="fileUpload">
                            <label className="upload">
                              Profile Picture
                              <TextValidator
                                className="w-full"
                                onChange={this.uploadsImage}
                                type="file"
                                value=""
                                name="name"
                                multiple={false}
                                inputProps={{
                                  accept: "image/*",
                                }}
                                error={logoError}
                              />

                              <img
                                src={
                                  Object.keys(sendLogoData).length > 0
                                    ? sendLogoData
                                    : logoSrc
                                      ? logoSrc
                                      : this.state.LogoUrl
                                }
                                className="profilePic"
                                alt="profile-pic"
                              />
                            </label>
                          </div>
                        </div>
                      </CardContent>
                      <CardActions className="button-right">
                        <Button
                          className="ml-2"
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          <Icon>send</Icon>
                          <span className="pl-2 capitalize">Update</span>
                        </Button>

                        <Button
                          className="ml-5"
                          variant="contained"
                          onClick={this.ClearFrom}
                          type="button"
                        >
                          <Icon>delete</Icon>
                          <span className="pl-2 capitalize">Cancel</span>
                        </Button>
                      </CardActions>
                      <br />
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </div>
        {loading && <MatxLoading />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IProps) => {
  const setUserData = PropTypes.func.isRequired;
  return {
    user: state.user,
    setUserData,
  };
};

export default withStyles(
  {},
  { withTheme: true }
)(connect(mapStateToProps, { setUserData })(withSnackbar(Profile)));
