import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";
// import { authRoles } from "../../auth/authRoles";

const durationRoutes = [
 {
path: `${SUB_DIRCTORY}/duration`,
  component: React.lazy(() => import("./index")),
 },
 
];

export default durationRoutes;
