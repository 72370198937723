import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const AccountRoutes = [
 {
  path: `${SUB_DIRCTORY}/account-reconcilation`,
  component: React.lazy(() => import("./index")),
 },

];

export default AccountRoutes;
