import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  Theme,
  WithStyles,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { commonMsg, showToast } from "./../../../constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withSnackbar, WithSnackbarProps } from "notistack";
import createStyles from "@material-ui/core/styles/createStyles";
import { postApiCall } from "../../services/_common";
import { forgotPassword, SUB_DIRCTORY } from "../../../app_config";
import { MatxLoading } from "../../../matx";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface IProps
  extends RouteComponentProps,
    WithSnackbarProps,
    WithStyles<typeof styles> {}

interface IState {
  email: string;
  isLoading: boolean;
}

class ForgotPassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: "",
      isLoading: false
    };
  }
  handleChange = (event: any) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    } as Pick<IState, keyof IState>);
  };
  handleFormSubmit = () => {
    const postData = {
      Email: this.state.email,
      BaseUrl: "admin"
    }
    this.setState({ isLoading: true });
      postApiCall(forgotPassword, postData)
        .then((response) => {
          if (response.Status) {
            this.setState({ isLoading: false });
            showToast(
              this.props.enqueueSnackbar,
              response.Message,
              "Success",
              "success",
              this.props.closeSnackbar
            );
            this.props.history.push({
              pathname: `${SUB_DIRCTORY}/session/signin`,
            });
          } else {
            this.setState({ isLoading: false });
            var Errormessage = response.Message === "User Not Found" ? "Email id is not registered with us" : "Email id is not registered with us";
            showToast(
              this.props.enqueueSnackbar,
              Errormessage,
              "Error",
              "error",
              this.props.closeSnackbar
            );
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
  };
  render() {
    const { email, isLoading } = this.state;

    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <div className="logo-box">
            <img src={SUB_DIRCTORY + "/assets/images/verticle-logo.png"} alt="Logo"/>
          </div>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
              
                <div className="p-8 flex justify-center items-center h-full img-w-100 resetpw-img">
                  <img src={SUB_DIRCTORY + "/assets/images/illustrations/dreamer.svg"} alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
             
                <div className="p-9 h-full bg-light-gray position-relative">
                 <div className="login-title"> <span>Forgot Password</span></div>
                  <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                    <TextValidator
                      className="mb-6 w-full"
                      required
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required", "isEmail"]}
                      errorMessages={commonMsg.EMAIL_ERR}
                    />
                    <div className="flex items-center">
                      <Button variant="contained" color="primary" type="submit">
                        Submit
                      </Button>
                      <span className="ml-4 mr-2">or</span>
                      <Button
                        className="text-primary"
                        onClick={() =>
                          this.props.history.push(`${SUB_DIRCTORY}/session/signin`)
                        }
                      >
                        Sign in
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
        {isLoading && <MatxLoading />}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withRouter(withSnackbar(ForgotPassword))
);
