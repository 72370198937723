import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";

import materialRoutes from "./views/material-kit/MaterialRoutes";
import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";

import formsRoutes from "./views/forms/FormsRoutes";
import mapRoutes from "./views/map/MapRoutes";
import dummyRoutes from "./views/dummy/DummyRoutes";
import ParkingLocationRoutes from "./views/parking-location/ParkingLocationRoutes";
import StaffRoutes from "./views/staff/StaffRoutes";
import ParkingBookingRoutes from "./views/parking-booking/ParkingBookingRoutes";
import DamagedVehicleRoutes from "./views/damaged-vehicle/DamagedVehicleRoutes";
import CheckInCheckoutRoutes from "./views/checkIn-checkOut/CheckInCheckoutRoutes";
import QrActivityLogsRoutes from "./views/qr-activity-logs/QrActivityLogsRoutes";
import BusinessOwnerRoutes from "./views/business-owner/BusinessOwnerRoutes";
import Cashpaymentroutes from "./views/Cash-Details/Cashpaymentroutes";
import AnprRoutes from "./views/anpr-feed/AnprRoutes";
import CameraRoutes from "./views/camera-setting/CameraRoutes";
import { SUB_DIRCTORY } from "../app_config";
import QrSettingRoutes from "./views/qr-setting/QRSettingRoutes";
import ReportRoutes from "./views/Report/ReportRoutes";
import OccupancyRoutes from './views/occupancy/OccupancyRoutes';
import Durationroutes from './views/duration/Durationroutes';
import LiveReports from "./views/live-reports/LiveReports";
import AccountRoutes from "./views/account-reconcilation/AccountRoutes";
import ExtenedMonthlyReportRoute from "./views/extendedMonthly-report/ExtenedMonthlyReportRoute";
import BookingRevenueRoutes from './views/Booking-Revenue/BookingRevenueRoute';
import BookingchargeRoutes from './views/account-receivable/bookingChargeRoute';
import ChargeListRoute from './views/Charge-back/ChargeListRoute';
import BusinessOfficeRoute from './views/business-office/BusinessOfficeRoute';
import ChargeBackCustomerRoute from './views/charge-back-customer/ChargeBackCustomerRoute';
import whiteListRoutes from './views/whiteList-vehicle/whiteListRoutes';
import ParkingLocationSettingRoutes from "./views/ParkingLocationSetting/parkingLocationSettingRoute";
import RoleBasedRedirect from "./RoleBasedRedirect";

const redirectRoute = [
  {
    path: `${SUB_DIRCTORY}/`,
    exact: true,
    component: () => <RoleBasedRedirect/>,
  },
  {
    path: "/",
    exact: true,
    component: () => <RoleBasedRedirect/>,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to={SUB_DIRCTORY + "/session/404"} />,
  },
];

const routes = [
  ...ParkingLocationSettingRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...dummyRoutes,
  ...ParkingLocationRoutes,
  ...CameraRoutes,
  ...AnprRoutes,
  ...StaffRoutes,
  ...OccupancyRoutes,
  ...BookingRevenueRoutes,
  ...whiteListRoutes,
  ...QrSettingRoutes,
  ...ParkingBookingRoutes,
  ...DamagedVehicleRoutes,
  ...Cashpaymentroutes,
  ...CheckInCheckoutRoutes,
  ...QrActivityLogsRoutes,
  ...ExtenedMonthlyReportRoute,
  ...AccountRoutes,
  ...BusinessOwnerRoutes,
  ...ReportRoutes,
  ...BookingchargeRoutes,
  ...ChargeListRoute,
  ...BusinessOfficeRoute,
  ...ChargeBackCustomerRoute,
  ...Durationroutes,
  ...LiveReports,
  ...materialRoutes,
  ...utilitiesRoutes,
  ...dragAndDropRoute,
  ...formsRoutes,
  ...mapRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
