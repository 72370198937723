import * as React from "react";
import { Grid, } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    paragrah:
    {
        textAlign: 'start', textTransform: 'uppercase',
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: 1.5,
        marginTop: "5px",
        marginBottom: "10px",
    }
});
export default function TermsPolicy() {
    const classes = useStyles();
    return (

        <Grid container style={{ alignItems: 'center', marginTop: '10px' }}>
            <Grid item xs={2} md={2} lg={2} sm={2} ></Grid>
            <Grid item xs={8} md={8} lg={8} sm={8} >
                <Card className={classes.root} variant="outlined" style={{ textAlign: 'center' }}>
                    <CardContent>
                        <Typography gutterBottom variant="h4" component="h1" >
                            - NOTICE -
                        </Typography>
                        <Typography variant="h5" component="h2">
                            WHERE APPLICABLE
                        </Typography>
                        <Typography className={classes.paragrah} >
                            Flix valet solution, Inc  is a virtual marketplace and social platform for all things
                            Cannabis, where recreational and medical marijuana consumers can
                            Connect with licensed and lawfully operated merchants.
                            Flix valet solution is not a merchant itself or a medical provider nor does it provide
                            medical advice or similar professional services.
                        </Typography>
                        <Typography className={classes.paragrah} style={{ fontWeight: 800 }}>
                            PRESEMT THIS CHECK WHEN Requesting  YOUR VEHICLE
                        </Typography>
                        <Typography className={classes.paragrah}  >
                            Flix valet solution, Inc  is a virtual marketplace and social platform for all things
                            Cannabis, where recreational and medical marijuana consumers can
                            Connect with licensed and lawfully operated merchants.
                            Flix valet solution is not a merchant itself or a medical provider nor does it provide
                            medical advice or similar professional services.
                        </Typography>
                        <Typography className={classes.paragrah} style={{ textAlign: 'center', fontWeight: 800 }}  >
                            PRESEMT THIS CHECK WHEN Requesting  YOUR VEHICLE
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={2} md={2} lg={2} sm={2} ></Grid>
        </Grid>
    );
}