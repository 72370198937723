import "../fake-db";
import "../styles/_app.scss";
/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect} from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "../history";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import MatxLayout from "./MatxLayout/MatxLayoutSFC";
import AuthGuard from "./auth/AuthGuard";
import { SnackbarProvider } from "notistack";
import { SUB_DIRCTORY } from "../app_config";



const App = () => {

  // useEffect(() => {
  //    if (window.location.protocol !== 'https:') {
  //       window.location.assign('https:' + window.location.href.substring(window.location.protocol.length));
  //       return // to stop app from mounting
  //     }
  // })


  return (
    <SnackbarProvider>
      <AppContext.Provider value={{ routes }}>
        <Provider store={Store}>
          <MatxTheme>
            <Auth>
              <Router basename={SUB_DIRCTORY} history={history}>
                <AuthGuard>
                  <MatxLayout />
                </AuthGuard>
              </Router>
            </Auth>
          </MatxTheme>
        </Provider>
      </AppContext.Provider>
    </SnackbarProvider>
  );
};

export default App;
