import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ParkingLocationSettingRoutes = [
  {
    path: `${SUB_DIRCTORY}/parkingLocationSetting/:action/:id?`,
    component: React.lazy(() => import("./addParkingLocationSetting")),
  },
  {
    path: `${SUB_DIRCTORY}/parkingLocationSetting`,
    component: React.lazy(() => import("./parkingLocationSetting")),
  },
];

export default ParkingLocationSettingRoutes;