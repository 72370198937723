import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";
// import { authRoles } from "../../auth/authRoles";

const Cashpaymentroutes = [
  
    {
        path: `${SUB_DIRCTORY}/Cash-details`,
        component: React.lazy(() => import("./Cash-details")),
    },
];

export default Cashpaymentroutes;