import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ChargeListRoute = [
 {
  path: `${SUB_DIRCTORY}/Charge-back/:action/`,
  component: React.lazy(() => import("./addChargeBakcStaff")),
 },
 {
  path: `${SUB_DIRCTORY}/Charge-back`,
  component: React.lazy(() => import("./ChargebackList")),
 },
];

export default ChargeListRoute;