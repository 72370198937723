import React from "react";
import { authRoles } from "../../auth/authRoles";

const dummyRoutes = [
  {
    path: "/dummy",
    component: React.lazy(() => import("./dummy")),
    auth: authRoles.admin
  }
];

export default dummyRoutes;
