import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ParkingBookingRoutes = [
  {
    path: `${SUB_DIRCTORY}/parking-booking/detail/:id`,
    component: React.lazy(() => import("./ParkingBookingDetails")),
  },
  {
    path: `${SUB_DIRCTORY}/parking-booking/monthlyparking`,
    component: React.lazy(() => import("./monthlyparking")),
  },
  {
    path: `${SUB_DIRCTORY}/parking-booking`,
    component: React.lazy(() => import("./ParkingBookingList")),
  },
];

export default ParkingBookingRoutes;