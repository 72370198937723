import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { SUB_DIRCTORY } from "../app_config";

const RoleBasedRedirect = () => {
  const userRole = useSelector((state) => state.user.Roles);
  let redirectPath = "/business-owner";

  if (userRole.includes("BusinessOffice")) {
    redirectPath = "/charge-back-customer";
  }

  return <Redirect to={SUB_DIRCTORY + redirectPath} />;
};

export default RoleBasedRedirect;
