// export const BASE_URL = "http://localhost:5050";
// export const BASE_URL = "http://208.76.253.98/commoncoreapi";
// export const BASE_URL = "http://18.191.133.56/valetparking-api";
// export const BASE_IMG_URL = "http://18.191.133.56/valetparking-api";

// Production url


// 1	Prepaid
// 2	Postpaid
// 3	NoCharge
// 4	ChargeBack


// export const BASE_URL = "http://192.168.0.58/FlixValetApi";
// export const BASE_IMG_URL = "http://192.168.0.58/api";
export const BASE_URL = "https://flixvalet.com/api";
export const BASE_IMG_URL = "https://flixvalet.com/api";

export const MapKey = "";
// export const SUB_DIRCTORY = "/valetparking-admin";
export const SUB_DIRCTORY = "";
export const googleLocationSearchContries = ["us", "in"];
export const imageMaxSize = "4000000";

//amezon s3 credencial
export const AccessKey = "AKIAQY4LJQSB4MN2ZRFO";
export const SecretKey = "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";
export const ImageBucketName = "flix-image";
export const region = "us-east-2";
export const AdminProfiles = "AdminProfiles";
export const LocationImages = "LocationImages";
export const BusinessLogoImages = "BusinessLogoImages";
// export const loginUrl = "/account/authenticate";
export const loginUrl = "/account/login";
export const getAllParkingLocations = "/ParkingOwnerServices/GetAllParkingLocations";
export const getParkingLocationById = "/ParkingOwnerServices/GetParkingLocationById";
export const addParkingLocation = "/ParkingOwnerServices/AddParkingLocation";
export const getAllBusinessOwners = "/SuperAdminServices/GetAllParkingBusinessOwners";
export const addBusinessOwner = "/SuperAdminServices/AddParkingBusinessOwner";
export const getParkingBusinessOwner = "/SuperAdminServices/GetParkingOwnerById";
export const uploadImage = "/Attachment/UploadImage";
export const getAllStaffMember = "/ParkingOwnerServices/GetAllStaffMembers";
export const getAllRole = "/account/GetRoles";
export const uploadsFile = "/Attachment/UploadImage";
export const getLocationByuserId = "/Master/GetLocationsByUser";
export const getBusinessOfficeByUserId = "/ParkingOwnerServices/GetBusinessOfficeByUserId";
export const addStaff = "/ParkingOwnerServices/addstaff";
export const setPassword = "/account/set-password";
export const changePassword = "/account/change-password"
export const forgotPassword = "/account/forgot-password";
export const resetPassword = "/account/reset-password";
export const getAStaffById = "/ParkingOwnerServices/GetStaffById";
export const getUserProfileDetails = "/Master/GetUserProfileDetails";
export const editUserProfile = "/Master/EditUserProfile";
export const getCountries = "/region/GetAllCountries";
export const getStates = "/region/GetStatesForCountry";
export const getAllParking = "/ParkingOwnerServices/GetBookingByParkingOwner";
export const GetBookingDetailsByBookingId = "/ParkingOwnerServices/GetBookingDetailsByBookingId";
export const GetDamageVehicleByParkingOwner = "/ParkingOwnerServices/GetDamageVehicleByParkingOwner";
export const GetCheckInOutListByUser = "/ParkingOwnerServices/GetCheckInOutListByUser";
export const GetQrActivityLogs = "/ParkingOwnerServices/GetQrActivityLogs";
// export const GetStaffByParkingOwner = "/ValetServices/GetStaffByParkingOwner";
export const GetStaffByParkingOwner = "/ParkingOwnerServices/GetStaffByParkingOwner";
export const GetDamageVehicleDetails = "/Master/GetDamageVehicleDetails";
export const AddOwnerPaymentSettings = "/ParkingOwnerServices/AddOwnerPaymentSettings";
export const GetOwnerPaymentSettings = "/ParkingOwnerServices/GetOwnerPaymentSettings";
export const ChangeStaffActiveStatus = "/ParkingOwnerServices/ChangeStaffActiveStatus";
export const GetDepositReport = "/Master/GetDepositReport";
export const DeleteParkingBusinessOwner = "/SuperAdminServices/DeleteParkingBusinessOwner";
export const DeleteStaff = "/ParkingOwnerServices/DeleteStaff";
export const DeleteParkingLocation = "/ParkingOwnerServices/DeleteParkingLocation";
export const GetdepositeALL = "/Master/GetDepositReport/";
export const AddLocationCameraSettings = "/ParkingOwnerServices/AddLocationCameraSettings/";
export const GetLocationCameraSettings = "/ParkingOwnerServices/GetLocationCameraSettings/";
export const GetCameraListByLocation = "/ParkingOwnerServices/GetCameraListByLocation/";
export const GetCameraSettingList = "/ParkingOwnerServices/GetCameraSettingList/";
export const GetRecognizedVehicleList = "/ParkingOwnerServices/GetRecognizedVehicleList/";
export const GetTimeZones = "/Region/GetTimeZones/";
export const DeleteCameraSettings = "/ParkingOwnerServices/DeleteCameraSettings";
export const GenerateDynamicQR = "/ParkingOwnerServices/GenerateDynamicQR/";
export const GetLocationQRList = "/ParkingOwnerServices/GetLocationQRList/";
export const GetOwnerLocationsWithoutQR = "/ParkingOwnerServices/GetOwnerLocationsWithoutQR/"
export const RevenueGraph = "/ParkingOwnerServices/RevenueGraph_v1";
export const TransactionsGraph = "/ParkingOwnerServices/TransactionsGraph_v1";
export const OccupancyGraph = "/ParkingOwnerServices/OccupancyGraph";
export const KeyStatistics = "/ParkingOwnerServices/KeyStatistics/";
export const KeyStatistics_PieGraphs = "/ParkingOwnerServices/KeyStatistics_PieGraphs";
export const LiveReport = "/ParkingOwnerServices/LiveReport_v1";
export const DurationsGraph = "/ParkingOwnerServices/DurationsGraphv1";
export const AccountReconcilation = "/ParkingOwnerServices/AccountReconcilationv1";
export const FetchCustomerDetails = "/ParkingOwnerServices/FetchCustomerDetails";
export const AddCustomerVehicle = "/CustomerServices/AddCustomerVehicle";
export const bookParkingLocationv1 = "/master/BookParkingLocationv1";
export const GetBookingExtensionPendingList = "/ParkingOwnerServices/GetBookingExtensionPendingList";
export const GetVehicleMasterData = "/master/GetVehicleMasterData";
export const SearchCustomersFromFilter = "ParkingOwnerServices/SearchCustomersFromFilter";
export const BookingRevenueReport = "/ParkingOwnerServices/BookingRevenueReport";
export const GetBookingRevenueCSVReport = "/ParkingOwnerServices/GetBookingRevenueCSVReport";
export const AddWhiteListCustomers = "/ParkingOwnerServices/AddWhiteListCustomers";
export const GetWhiteListCustomerById = "/ParkingOwnerServices/GetWhiteListCustomerById";
export const GetWhiteListCustomerList = "/ParkingOwnerServices/GetWhiteListCustomerList";
export const AddParkingOwnerBusinessOffice = "/ParkingOwnerServices/AddParkingOwnerBusinessOffice";
export const AddPOBusinessOfficeEmployee = "/ParkingOwnerServices/AddPOBusinessOfficeEmployee";
export const AddPOBusinessOfficeEmployeeV1 = "/ParkingOwnerServices/AddPOBusinessOfficeEmployeeV1";
export const AddCustomer = "/ParkingOwnerServices/AddCustomer";
export const GetPOBusinessOfficeList = "/ParkingOwnerServices/GetPOBusinessOfficeList";
export const GetAllPOBusinessOfficeList = "/ParkingOwnerServices/GetAllPOBusinessOfficeList";
export const GetCustomerVehicles = "/CustomerServices/GetCustomerVehicles/";
export const GetPOBusinessOfficeEmployeeList = "/ParkingOwnerServices/GetPOBusinessOfficeEmployeeList";
export const GetChargeBackCustomerBookingReport = "/ParkingOwnerServices/ChargeBackCustomerBookingReport";
export const GetPOBusinessOfficeEmployeeById = "/master/GetPOBusinessOfficeEmployeeById";
export const DeletePOBusinessOffice = "/ParkingOwnerServices/DeletePOBusinessOffice";
export const ChangeBusinessOfficeActiveStatus = "/ParkingOwnerServices/ChangeBusinessOfficeActiveStatus";
export const AccountReceivableReport = "/ParkingOwnerServices/AccountReceivableReport";
export const UpdateOfficeEmployeePayment = "/ParkingOwnerServices/UpdateOfficeEmployeePayment";
export const GetRelayTypes = "/ParkingOwnerServices/GetRelayTypes"
export const AddParkingLocationGateSettings = "/ParkingOwnerServices/AddParkingLocationGateSettings"
export const GetAllParkingLocationGateSettings = "/ParkingOwnerServices/GetAllParkingLocationGateSettings"
export const GetParkingLocationGateSettingById = "/ParkingOwnerServices/GetParkingLocationGateSettingById"
export const DeleteParkingLocationGateSettings = "/ParkingOwnerServices/DeleteParkingLocationGateSettings"
export const GetParkingGateSettingLocationList = "/ParkingOwnerServices/GetParkingGateSettingLocationList";
