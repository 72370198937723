import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const DamagedVehicleRoutes = [
  {
    path: `${SUB_DIRCTORY}/damaged-vehicle/detail/:id`,
    component: React.lazy(() => import("./DamagedVehicleDetail")),
  },
  {
    path: `${SUB_DIRCTORY}/damaged-vehicle`,
    component: React.lazy(() => import("./DamagedVehicleList")),
  },
];

export default DamagedVehicleRoutes;