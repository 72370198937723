import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  Theme,
  WithStyles,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { commonMsg, showToast } from "../../../constants";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { postApiCall } from "../../services/_common";
import { resetPassword, SUB_DIRCTORY } from "../../../app_config";
import history from "../../../history";
import createStyles from "@material-ui/core/styles/createStyles";
import { RouteComponentProps } from "react-router";

const queryString = require("query-string");

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      marginTop: 10,
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    cardHeight: {
      width: 700,
      // height: 225,
    },
  });

interface IProps
  extends RouteComponentProps,
  WithSnackbarProps,
  WithStyles<typeof styles> { }

interface IState {
  password: string;
  confirmPassword: string;
  isLoading: boolean;
}

class SetPassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isStrongPassword', (value) => {
      let strongPasswordRegx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (strongPasswordRegx.test(value)) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('confirmPasswordNotMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isStrongPassword');
  }

  handleChange = (event: any) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    } as Pick<IState, keyof IState>);
  };

  handleFormSubmit = (event: any) => {
    const params = queryString.parse(this.props.location.search);
    const postData = {
      Token: params.Token,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmPassword,
    };
    this.setState({ isLoading: true });
    postApiCall(resetPassword, postData)
      .then((res) => {
        if (res.Status) {
          this.setState({ isLoading: true });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Success",
            "success",
            this.props.closeSnackbar
          );
          history.push({
            pathname: `${SUB_DIRCTORY}/session/signin`,
          });
        } else {
          this.setState({ isLoading: true });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: true });
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  };

  render() {
    let { classes } = this.props;
    let {
      password,
      confirmPassword,
      isLoading,
    } = this.state;
    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <Card className="signup-card position-relative y-center ">
            <Grid container className={classes.cardHeight}>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  <img src={SUB_DIRCTORY + "/assets/images/illustrations/dreamer.svg"} alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleFormSubmit}
                  >
                    <TextValidator
                      required
                      className="mb-3 w-full"
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={['required', 'isStrongPassword']}
                      errorMessages={commonMsg.PASS_ERR}
                    />
                    <TextValidator
                      required
                      className="mb-3 w-full"
                      label="Confirm Password"
                      onChange={this.handleChange}
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      validators={['required', 'confirmPasswordNotMatch']}
                      errorMessages={commonMsg.CONFIRM_PASS_ERR}
                    />
                    <div className="flex items-center">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={isLoading}
                          type="submit"
                        >
                          Submit
                        </Button>
                        {isLoading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                      <span className="ml-4 mr-2">or</span>
                      <Button
                        className="text-primary "
                        onClick={() =>
                          this.props.history.push(`${SUB_DIRCTORY}/session/signin`)
                        }
                      >
                        Sign in
                      </Button>
                    </div>
                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(withSnackbar(SetPassword));
