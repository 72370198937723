import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
  Theme,
  WithStyles,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import { commonMsg, showToast } from "../../../constants";
import { withSnackbar, WithSnackbarProps } from "notistack";
import { loginSelector } from "../../redux/selectors";
import createStyles from "@material-ui/core/styles/createStyles";
import { MatxLoading } from "../../../matx";
import { SUB_DIRCTORY } from "../../../app_config";


const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface IProps
  extends RouteComponentProps,
  WithSnackbarProps,
  WithStyles<typeof styles> {
  Data: any;
  Status: any;
  Message: any;
  loading: boolean;
  loginWithEmailAndPassword: (postData: any) => any;
}

interface IState {
  email: string;
  password: string;
  isAgree: boolean;
  isAgreeError: boolean;
}

class SignIn extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isAgree: false,
      isAgreeError: false,
    };
  }



  componentDidUpdate = (prevProps: any) => {
    if (prevProps !== this.props) {
      if (this.props.Status) {
      } else {
        if (!this.props.loading && this.props.Message) {
          showToast(
            this.props.enqueueSnackbar,
            this.props.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      }
    }
    ValidatorForm.addValidationRule("isAgreeTerms", (value) => {
      return this.validateForm();
      // return true;
    });
  };

  handleChange = (event: any) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value,
    } as Pick<IState, keyof IState>);
  };
  handleFormSubmit = (event: any) => {
    const postData = {
      Email: this.state.email,
      Password: this.state.password,
    };
    if (this.validateForm()) {
      this.props.loginWithEmailAndPassword(postData);
    }

    else {
    }
  };

  handleChangeAgreeCheckbox = (event: any) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.checked,
      isAgreeError: !event.target.checked,
    } as Pick<IState, keyof IState>);
  };

  validateForm = () => {
    let isFormValid = true;
    if (!this.state.isAgree) {
      isFormValid = false;
      this.setState({ isAgreeError: true });
    } else {
      this.setState({ isAgreeError: false });
    }
    return isFormValid;
  };

  render() {
    let { email, password, isAgree, isAgreeError } = this.state;
    let { classes } = this.props;

    return (
      <div className="signup flex justify-center w-full h-full-screen">
        <div className="p-8">
          <div className="logo-box">
            <img src={SUB_DIRCTORY + "/assets/images/verticle-logo.png"} alt="Logo" />
          </div>
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-8 flex justify-center items-center h-full">
                  <img src={SUB_DIRCTORY + "/assets/images/illustrations/dreamer.svg"} alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-9 h-full bg-light-gray position-relative">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.handleFormSubmit}
                    onError={(errors: any) => {

                      isAgree
                        ? this.setState({ isAgreeError: false })
                        : this.setState({ isAgreeError: true });
                    }}
                  >
                    <TextValidator
                      className="mb-6 w-full"
                      required
                      label="Email"
                      onChange={this.handleChange}
                      type="email"
                      name="email"
                      value={email}
                      validators={["required"]}
                      errorMessages={commonMsg.EMAIL_ERR}
                    />

                    <TextValidator
                      required
                      className="mb-3 w-full"
                      label="Password"
                      onChange={this.handleChange}
                      name="password"
                      type="password"
                      value={password}
                      validators={["required"]}
                      errorMessages={commonMsg.PASS_ERR}
                    />
                    <FormControlLabel
                      value={isAgree}
                      onChange={this.handleChangeAgreeCheckbox}
                      name="isAgree"
                      control={
                        <Checkbox
                          checked={isAgree}

                        />
                      }
                      label=""
                    />
                    <span className="ml-0">
                      I have read and agree to the
                      <a
                        href="/terms-of-service"
                        target="_blank"

                      > terms of service. </a>
                    </span>

                    <FormHelperText className="color-danger term-error">
                      {isAgreeError ? commonMsg.AGREE_TERMS : ""}
                    </FormHelperText>
                    <div className="flex flex-wrap items-center mb-4">
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.props.loading}
                          type="submit"
                        >
                          Sign in
                        </Button>
                        {this.props.loading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                      <span className="mr-2 ml-5">or</span>
                      <Button
                        className="text-primary"
                        onClick={() =>
                          this.props.history.push(`${SUB_DIRCTORY}/session/forgot-password`)
                        }
                      >
                        Forgot password?
                      </Button>
                    </div>

                  </ValidatorForm>
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
        {this.props.loading && <MatxLoading />}
      </div>
    );
  }
}

const mapStateToProps = (state: IProps) => {
  const { error, loading } = loginSelector(state);
  const loginWithEmailAndPassword = PropTypes.func.isRequired;
  return {
    Data: error.Data,
    Status: error.Status,
    Message: error.Message,
    loading,
    loginWithEmailAndPassword,
  };
};

export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(mapStateToProps, { loginWithEmailAndPassword })(
      withSnackbar(SignIn)
    )
  )
);