import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ExtenedMonthlyReportRoute = [
 
 {
  path: `${SUB_DIRCTORY}/extendedMonthly-report`,
  component: React.lazy(() => import("./index")),
 },


];

export default ExtenedMonthlyReportRoute;
