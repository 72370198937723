import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ParkingLocationRoutes = [
  {
    path: `${SUB_DIRCTORY}/parking-location/:action/:id?`,
    component: React.lazy(() => import("./AddParkingLocation")),
  },
  {
    path: `${SUB_DIRCTORY}/parking-location`,
    component: React.lazy(() => import("./ParkingLocation")),
  },
];

export default ParkingLocationRoutes;
