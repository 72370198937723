import accountService from "../../services/accountService";
import history from "../../../history";
// import jwtAuthService from "../../services/jwtAuthService";
import { SUB_DIRCTORY } from "../../../app_config";

export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export function setUserData(user) {
  return dispatch => {
    dispatch({
      type: SET_USER_DATA,
      data: user
    });
  };
}

export function logoutUser() {
  return dispatch => {
    accountService.logout();

    history.push({
      pathname: `${SUB_DIRCTORY}/session/signin`
    });

    dispatch({
      type: USER_LOGGED_OUT
    });
  };
}
