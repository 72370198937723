import React, { Component, Fragment } from "react";
import {
  Card,
  Grid,
  Paper,
  Button,
  CardActions,
  Theme,
  WithStyles,
  FormHelperText,
  CardContent,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";

import { withStyles } from "@material-ui/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withSnackbar, WithSnackbarProps } from "notistack";
import createStyles from "@material-ui/core/styles/createStyles";
import { postApiCall, getApiCall } from "../../services/_common";
import {
  AddOwnerPaymentSettings,
  GetOwnerPaymentSettings,
  SUB_DIRCTORY,
} from "../../../app_config";
import { commonMsg, showToast } from "./../../../constants";
import { Breadcrumb, MatxLoading } from "../../../matx";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface IProps
  extends RouteComponentProps,
    WithSnackbarProps,
    WithStyles<typeof styles> {
  user: any;
}

interface IState {
  isLoading: boolean;
  paymentType: string;
  paymentTypeError: boolean;
  PublishKey: string;
  SecretKey: string;
  SeApplicationId: string;
  SeAccessToken: string;
  PaymentSettingsId: string;
  seLocationId: string;
  Production: string;
  paypalPublickey: string;
  paypalPrivateKey: string;
  paypalTokenizationkey: string;
  paypalMerchentID: string;
}

class PaymentSetting extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      paymentType: "",
      paymentTypeError: false,
      PublishKey: "",
      SecretKey: "",
      SeApplicationId: "",
      SeAccessToken: "",
      seLocationId: "",
      PaymentSettingsId: "",
      Production: "",
      paypalPublickey: "",
      paypalPrivateKey: "",
      paypalTokenizationkey: "",
      paypalMerchentID: "",
    };
  }
  componentDidMount() {
    this.getPaymentMethodsByaonwerId();
  }

  handleChangeCheckbox = (event: any) => {
    this.setState({ Production: event.target.value });
  };

  getPaymentMethodsByaonwerId = () => {
    this.setState({ isLoading: true });
    const parms = `ParkingBuisnessOwnerId=${this.props.user.ParkingBusinessOwnerId}`;
    getApiCall(GetOwnerPaymentSettings, parms)
      .then((response) => {
        if (response.Status) {
          if (response.Data) {
            this.setState({ isLoading: true });

            if (response.Data.PaymentMethod === "Stripe") {
              this.setState({
                isLoading: false,
                Production:
                  response.Data.IsProduction === true
                    ? "Production"
                    : "Sandbox",
                paymentType: response.Data.PaymentMethod,
                PublishKey: response.Data.ApiKey,
                SecretKey: response.Data.SecretKey,
                PaymentSettingsId: response.Data.PaymentSettingsId,
                SeApplicationId: "",
                SeAccessToken: "",
                seLocationId: "",
                paypalPublickey: "",
                paypalTokenizationkey: "",
                paypalPrivateKey: "",
                paypalMerchentID: "",
              });
            } else if (response.Data.PaymentMethod === "Paypal") {
              this.setState({
                isLoading: false,
                Production:
                  response.Data.IsProduction === true
                    ? "Production"
                    : "Sandbox",
                paymentType: response.Data.PaymentMethod,
                paypalPublickey:
                  response.Data.ApiKey && response.Data.ApiKey.trim(),
                paypalPrivateKey:
                  response.Data.SecretKey && response.Data.SecretKey.trim(),
                paypalTokenizationkey:
                  response.Data.ApplicationId &&
                  response.Data.ApplicationId.trim(),
                paypalMerchentID:
                  response.Data.LocationId && response.Data.LocationId.trim(),
                PaymentSettingsId: response.Data.PaymentSettingsId,
                SeApplicationId: "",
                SeAccessToken: "",
                seLocationId: "",
                PublishKey: "",
                SecretKey: "",
              });
            } else {
              this.setState({
                isLoading: false,
                Production:
                  response.Data.IsProduction === true
                    ? "Production"
                    : "Sandbox",
                paymentType: response.Data.PaymentMethod,
                SeApplicationId: response.Data.ApplicationId,
                SeAccessToken: response.Data.AccessToken,
                seLocationId: response.Data.LocationId,
                PaymentSettingsId: response.Data.PaymentSettingsId,
                PublishKey: "",
                SecretKey: "",
                paypalPublickey: "",
                paypalTokenizationkey: "",
                paypalPrivateKey: "",
                paypalMerchentID: "",
              });
            }
          }
        } else {
          this.setState({ isLoading: false });
          showToast(
            this.props.enqueueSnackbar,
            response.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  };

  handleSubmit = () => {
    if (this.state.paymentType === "") {
      this.setState({ paymentTypeError: true });
      return;
    }

    const postData: any = {
      ParkingBusinessOwnerId: this.props.user.ParkingBusinessOwnerId,
      PaymentMethod: this.state.paymentType,
      ApiKey:
        this.state.paymentType === "Stripe"
          ? this.state.PublishKey.trim()
          : this.state.paymentType === "Paypal"
          ? this.state.paypalPublickey.trim()
          : null,
      SecretKey:
        this.state.paymentType === "Stripe"
          ? this.state.SecretKey.trim()
          : this.state.paymentType === "Paypal"
          ? this.state.paypalPrivateKey.trim()
          : null,
      ApplicationId:
        this.state.paymentType === "Square"
          ? this.state.SeApplicationId.trim()
          : this.state.paymentType === "Paypal"
          ? this.state.paypalTokenizationkey.trim()
          : null,
      LocationId:
        this.state.paymentType === "Square"
          ? this.state.seLocationId.trim()
          : this.state.paymentType === "Paypal"
          ? this.state.paypalMerchentID.trim()
          : null,
      AccessToken:
        this.state.paymentType === "Square"
          ? this.state.SeAccessToken.trim()
          : null,
      IsProduction: this.state.Production === "Production" ? true : false,
    };
    if (this.state.PaymentSettingsId) {
      postData.PaymentSettingsId = this.state.PaymentSettingsId;
    }

    this.setState({ isLoading: true });
    postApiCall(AddOwnerPaymentSettings, postData)
      .then((res) => {
        if (res.Status) {
          this.setState({ isLoading: false });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Success",
            "success",
            this.props.closeSnackbar
          );
          this.getPaymentMethodsByaonwerId();
        } else {
          this.setState({ isLoading: false });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  };

  handleChange = (event: any) => {
    event.persist();
    this.setState(
      { [event.target.name]: event.target.value } as Pick<IState, keyof IState>,
      () => {
        if (
          event.target.name === "paymentType" &&
          this.state.paymentType !== ""
        ) {
          this.setState({ paymentTypeError: false });
        }
      }
    );
  };

  render() {
    const {
      isLoading,
      paymentType,
      paymentTypeError,
      PublishKey,
      SecretKey,
      SeApplicationId,
      paypalTokenizationkey,
      paypalPublickey,
      paypalPrivateKey,
      paypalMerchentID,
      SeAccessToken,
      PaymentSettingsId,
      Production,
      seLocationId,
    } = this.state;

    return (
      <Fragment>
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                {
                  name: "Payment Settings",
                  path: `${SUB_DIRCTORY}/session/payment-setting`,
                },
              ]}
            />
          </div>
          <div className="custom-wrapper">
            <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
              <Grid container spacing={3}>
                <Grid className="marginauto" item xs={12} sm={12} md={7} lg={7}>
                  <Paper className="paper">
                    <Card>
                      <CardContent>
                        <div>
                          <FormControl
                            component="fieldset"
                            className="marginTenpx"
                            error={paymentTypeError}
                          >
                            <FormLabel component="legend">
                              Payment Type
                            </FormLabel>
                            <RadioGroup
                              value={paymentType}
                              name="paymentType"
                              onChange={this.handleChange}
                              row
                            >
                              <FormControlLabel
                                value="Stripe"
                                control={<Radio color="primary" />}
                                label="Stripe Payment"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="Square"
                                control={<Radio color="primary" />}
                                label="Square Payment"
                                labelPlacement="end"
                              />

                              <FormControlLabel
                                value="Paypal"
                                control={<Radio color="primary" />}
                                label="Paypal"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                            <FormHelperText>
                              {paymentTypeError
                                ? commonMsg.PaymentTypeError
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </div>
                        {paymentType && paymentType === "Stripe" && (
                          <div>
                            <TextValidator
                              className="mb-4 w-full"
                              id="PublishKey"
                              label="Stripe Publishable Key"
                              name="PublishKey"
                              value={PublishKey}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.PublishError}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="SecretKey"
                              label="Stripe Secret Key"
                              name="SecretKey"
                              value={SecretKey}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.SecretKeyError}
                              onChange={this.handleChange}
                              type="text"
                            />

                            <RadioGroup
                              value={Production}
                              name="Production"
                              onChange={this.handleChangeCheckbox}
                              row
                            >
                              <FormControlLabel
                                value="Production"
                                control={<Radio color="primary" />}
                                label="Production"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="Sandbox"
                                control={<Radio color="primary" />}
                                label="Sandbox"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </div>
                        )}
                        {paymentType && paymentType === "Square" && (
                          <div>
                            <TextValidator
                              className="mb-4 w-full"
                              id="SeApplicationId"
                              label="Square Application Id"
                              name="SeApplicationId"
                              value={SeApplicationId}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.SeApplicationIdError}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="SeAccessToken"
                              label="Square Access Token "
                              name="SeAccessToken"
                              value={SeAccessToken}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.SeAccessTokenError}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="seLocationId"
                              label="Square Location Id "
                              name="seLocationId"
                              value={seLocationId}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.seLocationId}
                              onChange={this.handleChange}
                              type="text"
                            />

                            <RadioGroup
                              value={Production}
                              name="Production"
                              onChange={this.handleChangeCheckbox}
                              row
                            >
                              <FormControlLabel
                                value="Production"
                                control={<Radio color="primary" />}
                                label="Production"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="Sandbox"
                                control={<Radio color="primary" />}
                                label="Sandbox"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </div>
                        )}
                        {paymentType && paymentType === "Paypal" && (
                          <div>
                            <TextValidator
                              className="mb-4 w-full"
                              id="paypalTokenizationkey"
                              label="Tokenization key"
                              //tokenize key
                              name="paypalTokenizationkey"
                              value={paypalTokenizationkey}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.PAYPALTOKEN}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="paypalMerchentID"
                              label="Merchant ID"
                              // location id
                              name="paypalMerchentID"
                              value={paypalMerchentID}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.PAYPALMerchant}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="paypalPublickey"
                              label="Public Key"
                              name="paypalPublickey"
                              value={paypalPublickey}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.PAYPALPublish}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <TextValidator
                              className="mb-4 w-full"
                              id="paypalPrivateKey"
                              label="Private key"
                              name="paypalPrivateKey"
                              value={paypalPrivateKey}
                              validators={commonMsg.BASIC_VALIDATORS}
                              errorMessages={commonMsg.PAYPALPrivate}
                              onChange={this.handleChange}
                              type="text"
                            />
                            <RadioGroup
                              value={Production}
                              name="Production"
                              onChange={this.handleChangeCheckbox}
                              row
                            >
                              <FormControlLabel
                                value="Production"
                                control={<Radio color="primary" />}
                                label="Production"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="Sandbox"
                                control={<Radio color="primary" />}
                                label="Sandbox"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </div>
                        )}
                      </CardContent>
                      <CardActions className="button-right">
                        <Button
                          className="ml-2"
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          <span className="pl-2 capitalize">
                            {PaymentSettingsId === "" ? "SAVE" : "SAVE"}
                          </span>
                        </Button>
                      </CardActions>
                      <br />
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </div>
        {isLoading && <MatxLoading />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: IProps) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withSnackbar(withRouter(PaymentSetting))));
