import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";
// import { authRoles } from "../../auth/authRoles";

const BusinessOwnerRoutes = [
  {
    path: `${SUB_DIRCTORY}/business-owner/:action/:id?`,
    component: React.lazy(() => import("./BusinessOwnerDetail")),
  },
  {
    path: `${SUB_DIRCTORY}/business-owner`,
    component: React.lazy(() => import("./BusinessOwnerList")),
  },
];

export default BusinessOwnerRoutes;