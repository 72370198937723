import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const BookingRevenueRoute = [
 {
  path: `${SUB_DIRCTORY}/Booking-Revenue`,
  component: React.lazy(() => import("./BookingRevenueList")),
 },
];

export default BookingRevenueRoute;