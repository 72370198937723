export const commonMsg = {
  ATLEAST_ONE_RATE: "Please add atleast one rate",
  PARKNG_LOC_ERR: ["Please enter Parking Location Name", "Please enter Location Name ", "Parking Location Name must be less than 100"],
  PARKNG_LOC_ADD_ERR: "Please enter Parking Location Address",
  ZIP_ERR: "Please enter Zip Code",
  ZIP_MAX_ERR: "Zip Code must be less than 11",
  PHONE_ERR_Valid: "please enter Valid number",
  ZIP_Valid: "please Enter Valid Zip Code",
  CITY_ERR: "Please enter City",
  STATE_ERR: "Please select State",
  COUNTRY_ERR: "Please select Country",
  PARK_SLOTS_RQ_ERR: "Please enter No of Parking Slots",
  MIN_1_PARK_SLOTS_RQ_ERR: "Parking Slots can not be less than 1",
  MAX_PARK_SLOTS_RQ_ERR: "Max limit over of Marking location",
  FRM_TIME_RQ_ERR: "Please enter From Time",
  TO_TIME_RQ_ERR: "Please enter To Time",
  TIME_RNG_ERR: "Time Range is not correct",
  TIME_RNG_ERR_To_Time: "To Time should be greater than From Time",
  TIME_RNG_ERR_From_Time: "From Time should be less than To Time",
  DURATION_RQ_ERR: "Please select Duration",
  CHRAGES_RQ_ERR: "Please enter Charges",
  ENTR_BY_RQ_ERR: "Please enter Enter By",
  ENTR_TO_RQ_ERR: "Please enter Enter To",
  EXIT_BY_RQ_ERR: "Please enter Exit By",
  CONVENIENCE_FEES: ['Please enter only decimal value', 'Please enter Convenience Fees'],
  PRICE_RQ_ERR: "Please enter Price",
  MIN_1_DAY_RQ_ERR: "Please select alteast one day",
  CONF_DEL_LOC: "Are you sure you want to delete this location?",
  DEL_CONF_POP_TITLE: "Delete Parking Location Confirmation?",
  DEL_LOC_SUCC: "Parking Location Deleted Successfully.",
  LOGO_ERR: "Please select Logo",
  PARKING_VALIDATION: ["required", "minStringLength:1", "maxStringLength:100"],
  BUSI_TITLE_VALIDATION: ["required", "minStringLength:1", "maxStringLength:50"],
  // BUSI_TITLE_ERR: ["Please enter Business Title", "Business Title must be greater than 0 ","Business Title must be less than 50"],
  BUSI_TITLE_ERR: ["Please enter Business Title", "Please enter Business Title ", "Business Title must be less than 50"],

  FIRST_NAME_VALIDATORS: ["required", "minStringLength:1", "maxStringLength:50", 'matchRegexp:^[a-zA-Z ]*$'],
  // FIRST_NAME_ERR: ["Please enter First Name","First Name must be greater than 0 ","First Name must be less than 50",'Please enter valid first name'],
  FIRST_NAME_ERR: ["Please enter First Name", "Please enter First Name ", "First Name must be less than 50", 'Please Enter only Alphabets based Input. No Digits No Special Character.'],

  LAST_NAME_ERR: ["Please enter Last Name", "Please enter Last Name ", "Last Name must be less than 50", 'Please Enter only Alphabets based Input. No Digits No Special Character.'],
  GENDER_ERR: "Please select Gender",
  CAMERA_ERR: ["Please enter Camera id", "Please enter Camera id", "Please enter Camera id", "Please enter valid Camera id"],
  CAMERA_VALIDATORS: ["minStringLength:1", "maxStringLength:100", 'matchRegexp:^[0-9]*$'],
  EMAIL_VALIDATORS: ["minStringLength:0", "minStringLength:6", "maxStringLength:100", "isEmail"],
  EMAIL_ERR: ["Please enter Email", "Please enter valid  Email", "Please enter Email", "Please enter valid Email"],
  EMAIL_ERR1: ["Please enter Email id", "Please enter Email id", "Please enter Email", "Please enter Email id"],

  PHONE_VALIDATORS: ["required", "minStringLength:14", "maxStringLength:20", 'matchRegexp:^[0-9]*$'],
  PHONE_ERR: ["Please enter Phone number", "Please enter valid Phone number",
    "Phone number should be between 10 to 15 digits", "Please enter valid Phone number",],

  // PHONE_ERR: ["Please enter Phone number", "Please enter valid Phone number","Phone number should be between 10 to 15 digits","Please enter valid Phone number"],
  DELETE_OWNER_HEADING: "Delete Business Owner Confirmation ?",
  DELETE_CONFIRMATION: "Are you sure you want to delete this business owner. ?",
  ROLE_ERR: "Please select Role",
  LOCA_ERR: "Please select location",
  BUSINESS_OFF: "Please select Business Office",
  CAMERA_ENTER_EXIT: "Please select camera location",
  LOCATION_ERR: ["Please enter Location", "Please select one location if role ParkingManager or Valet."],
  ADD_ERR: "Please enter Address",
  PROFILEERR: "Please select Profile Picture",
  CONF_DEL_OWNR: "Are you sure you want to delete this owner?",
  DEL_CONF_OWNR_TITLE: "Delete Business owner Confirmation?",
  DEL_OWNR_SUCC: "Parking Owner Deleted Successfully.",
  PASS_ERR: ["Please enter your Password.", "Password must have minimum 8 characters, with at least 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character."],
  CONFIRM_PASS_ERR: ["Please confirm your Password.", "Password and Confirm Password fields must be identical. Please try again."],
  ADD_ATLST_ONE: "Please add hours for atleast one day.",
  RQRD_FLD: "Please fill required fields.",
  AGREE_TERMS: "Please accept terms of services.",
  USERNAME_ERR: "Please enter your Username.",
  DELETE_STAFF_COMFIRMATION: "Delete Staff Confirmation ?",
  DELETE_STAFF_SUCCESS: "Staff Deleted Successfully.",
  CONFIRM_DELETE_STAFF: "Are you sure you want to delete this staff. ?",
  OPEN_DIALOG: "Open responsive dialog",
  USE_GOOGLE_LOCATION: "Use Google's location service?",
  GOOGLE_LOCATION_APP_INFO: "Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.",
  IMG_SIZ_MSG: "Image can not be more than 4 mb",
  LIC_IMG_RQD: "Please select License Image",
  LIC_EXP_RQD: "Please end License Expiry Date",
  DAY_SELCT_MSG: "Please select day to copy time interval.",
  TWO_DECI_VAL: "Please enter only two decimal value",
  emailAlreadyexits: "Email Id already registered with us.",
  PaymentTypeError: "Please Select Payment Type",
  BASIC_VALIDATORS: ["required", "minStringLength:10", "maxStringLength: 200"],
  PublishError: ["Please enter Stripe Publishable Key", "Stripe Publishable Key must be greater than 10 ", "Stripe Publishable Key must be less than 200"],
  SecretKeyError: ["Please enter Stripe Secret Key", "Stripe Secret Key must be greater than 10 ", "Stripe Secret Key must be less than 200"],
  SeApplicationIdError: ["Please enter Square Application Id", "Square Application Id must be greater than 10 ", "Square Application Id must be less than 200"],
  SeAccessTokenError: ["Please enter Square Access Token", "Square Access Token must be greater than 10 ", "Square Access Token must be less than 200"],
  seLocationId: ["Please enter Square Location Id", "Location Id must be greater than 10 ", "Location Id must be less than 200"],
  NoLocationError: "No Location Found",
  ValidAmount: "Please enter valid amount",
  ValidAmount1: "Entered amount should not more than remaining amount",
  PAYPALTOKEN: "Please enter Tokenization key ",
  PAYPALMerchant: "Please enter Merchant Id ",
  PAYPALPrivate: "Please enter Private key  ",
  PAYPALPublish: "Please enter Publish key ",
  systenIP: "Please enter System IP ",
  relayURL: "Please enter relay URL ",
  enterGateChecked: "Please click enter gate checkbox",
  exitGateChecked: "Please click exit gate checkbox",
  exitGatReleyNameError: "Please enter exit gate relay name",
  enterGatReleyNameError: "Please fill Enter gate relay name",
  RelayTypeIdError: "Please select relay name",
  Relay_VALIDATORS: ["required", "minStringLength:5", "maxStringLength: 50"],
  monthsError: "Please select months",
}

