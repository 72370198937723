import React, { Component, Fragment } from "react";
import {
  Card,
  Grid,
  Paper,
  Button,
  CardActions,
  Theme,
  WithStyles,
  CardContent
} from "@material-ui/core";
import history from "../../../history";
import { withStyles } from "@material-ui/styles";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withSnackbar, WithSnackbarProps } from "notistack";
import createStyles from "@material-ui/core/styles/createStyles";
import { postApiCall } from "../../services/_common";
import { changePassword, SUB_DIRCTORY } from "../../../app_config";
import { commonMsg, showToast } from "./../../../constants";
import { Breadcrumb, MatxLoading } from "../../../matx";

const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
    },

    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  });

interface IProps
  extends RouteComponentProps,
  WithSnackbarProps,
  WithStyles<typeof styles> { user: any; }

interface IState {
  password: string;
  confirmPassword: string;
  isLoading: boolean;
}

class ChangePassword extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      isLoading: false,
    };
  }
  componentDidMount() {
    ValidatorForm.addValidationRule('isStrongPassword', (value) => {
      let strongPasswordRegx = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (strongPasswordRegx.test(value)) {
        return true;
      }
      return false;
    });

    ValidatorForm.addValidationRule('confirmPasswordNotMatch', (value) => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isStrongPassword');
  }

  handleChange = (event: any) => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    } as Pick<IState, keyof IState>);
  };


  handleSubmit = () => {
    const postData = {
      UserId: this.props.user.Id,
      Password: this.state.password,
      ConfirmPassword: this.state.confirmPassword,
    };
    this.setState({ isLoading: true });
    postApiCall(changePassword, postData)
      .then((res) => {
        if (res.Status) {
          this.setState({ isLoading: true });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Success",
            "success",
            this.props.closeSnackbar
          );
          history.push({
            pathname: `${SUB_DIRCTORY}/`,
          });
        } else {
          this.setState({ isLoading: true });
          showToast(
            this.props.enqueueSnackbar,
            res.Message,
            "Error",
            "error",
            this.props.closeSnackbar
          );
        }
      })
      .catch((error) => {
        this.setState({ isLoading: true });
        showToast(
          this.props.enqueueSnackbar,
          error,
          "Error",
          "error",
          this.props.closeSnackbar
        );
      });
  };
  render() {
    const {
      password,
      confirmPassword,
      isLoading } = this.state;

    return (
      <Fragment>
        <div className="m-sm-30">
          <div className="mb-sm-30">
            <Breadcrumb
              routeSegments={[
                { name: "Change Password", path: `${SUB_DIRCTORY}/session/change password` }
              ]}
            />
          </div>
          <div className="custom-wrapper">
            <ValidatorForm
              ref="form"
              onSubmit={this.handleSubmit}>
              <Grid container spacing={3}>
                <Grid className="marginauto" item xs={12} sm={12} md={6} lg={6}>
                  <Paper className="paper">
                    <Card>
                      <CardContent>
                        <div>
                          <TextValidator
                            required
                            className="mb-4 w-full"
                            id="password"
                            label="New Password"
                            name="password"
                            onChange={this.handleChange}
                            value={password}
                            validators={['required', 'isStrongPassword']}
                            errorMessages={commonMsg.PASS_ERR}
                            type="password"
                          />

                          <TextValidator
                            required
                            className="mb-3 w-full"
                            label="Confirm Password"
                            onChange={this.handleChange}
                            name="confirmPassword"
                            type="password"
                            value={confirmPassword}
                            validators={['required', 'confirmPasswordNotMatch']}
                            errorMessages={commonMsg.CONFIRM_PASS_ERR}
                          />
                        </div>
                      </CardContent>
                      <CardActions className="button-right">
                        <Button
                          className="ml-2"
                          color="primary"
                          variant="contained"
                          type="submit"
                        >
                          <span className="pl-2 capitalize">
                            Save Password
                          </span>
                        </Button>
                      </CardActions>
                      <br />
                    </Card>
                  </Paper>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        </div>
        {isLoading && <MatxLoading />}
      </Fragment>
    );

  }
}

const mapStateToProps = (state: IProps) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withSnackbar(withRouter(ChangePassword))));