import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const BusinessOfficeRoute = [
 {
  path: `${SUB_DIRCTORY}/business-office/:action/`,
  component: React.lazy(() => import("./addBusinessOffice")),
 },
 {
  path: `${SUB_DIRCTORY}/business-office`,
  component: React.lazy(() => import("./businessOfficeList")),
 },
];

export default BusinessOfficeRoute;