import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";
// import { authRoles } from "../../auth/authRoles";

const occupancyRoutes = [
 {
  path: `${SUB_DIRCTORY}/occupancy`,
  component: React.lazy(() => import("./index")),
 },

];

export default occupancyRoutes;
