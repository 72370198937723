import React from "react";
import { SUB_DIRCTORY } from "../../../app_config";

const ChargeBackCustomerRoute = [
 {
  path: `${SUB_DIRCTORY}/charge-back-customer/:action/`,
  component: React.lazy(() => import("./addChargeBackCustomer")),
 },
 {
  path: `${SUB_DIRCTORY}/charge-back-customer-report`,
  component: React.lazy(() => import("./chargeBackCustomerReport")),
 },
 {
  path: `${SUB_DIRCTORY}/charge-back-customer`,
  component: React.lazy(() => import("./chargeBackCustomerList")),
 },
];

export default ChargeBackCustomerRoute;