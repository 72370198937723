// import jwtAuthService from "../../services/jwtAuthService";
// import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "../../../history";
import accountService from "../../services/accountService";
import { loginUrl, SUB_DIRCTORY } from "../../../app_config";
// import { showToast } from "../../../constants";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

export function loginWithEmailAndPassword(postData) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    accountService
      .loginWithEmailAndPassword(loginUrl, postData)
      .then(response => {
        if(response.Status) {

          if(response.Data.IsSuperAdmin){
            dispatch(setUserData(response.Data));
            history.push({
            pathname: `${SUB_DIRCTORY}/`
          });
          return dispatch({
             type: LOGIN_SUCCESS
           });
          }else{

            if(response.Data.Roles.includes("SuperAdmin") || response.Data.Roles.includes("ParkingAdmin") || response.Data.Roles.includes("ParkingSupervisor")){
               dispatch(setUserData(response.Data));
                history.push({
                  pathname: `${SUB_DIRCTORY}/dashboard`
                  });
                   return dispatch({
                    type: LOGIN_SUCCESS
                  });
            }else if(response.Data.Roles.includes("BusinessOffice")) {
              history.push({
                pathname: `${SUB_DIRCTORY}/charge-back-customer`
                });
                 return dispatch({
                  type: LOGIN_SUCCESS
                });
            }else{
               history.push({
                pathname: `${SUB_DIRCTORY}/session/signin`
              });
             localStorage.clear();
             return dispatch({
               type: LOGIN_ERROR,
               payload: {status: false, Message: "You are not authorized for website"}
              });

            }

          }
        }
        else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: response
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

export function resetPassword({ email }) {
  return dispatch => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD
    });
  };
}
